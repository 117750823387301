.nav-trigger {
	z-index: 100;
	position: absolute;
	top: 18px;
	right: 40px;
	width: 38px;
	height: 38px;
	padding: 12px 5px;
	margin-top: 1.2vw;
	cursor: pointer;
}

.nav {
	z-index: 200;
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 5vh;
	right: -40px;
	height: 90vh;
	width: 270px;
	padding: 45px 75px 25px 25px;
	border-radius: 40px;
  box-shadow: 0 13px 69px 17px rgba(0, 0, 0, 0.27);
  background-color: #ffffff;
  transform: translate(320px, 0);
  transition: transform, .5s $CubicEaseInOut;

  &.active {
  	transform: translate(0,0);
  }

  .close {
  	position: absolute;
  	top: 20px;
  	left: 30px;
  	cursor: pointer;
  }

  .profile-section {
  	padding-bottom: 10px;
  	border-bottom: 1px solid #d9deeb;
  }

  .nav-links {
  	flex-grow: 1;
  	padding: 10px 0;
  	height: 100;
  }

  .nav-footer {
  	padding-top: 10px;
  	border-top: 1px solid #d9deeb;
  }

  .avatar {
  	height: 90px;
  	width: 135px;
  	padding-left: 45px;
  	margin-top: 20px;
  }

  p {
  	font-size: 16px;
  	line-height: 1.5;

  	.profile-name {
  		font-weight: 700;
  	}
  }

  .nav-item {
  	display: flex;;
  	margin: 10px;
  	align-items: center;
		color: #002277;

  	span {
  		margin-left: 10px;
  	}
  }
}
