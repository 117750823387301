.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModalPortal > * {
    opacity: 0;
}

.overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: rgba(0,0,0,.15);

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.modal {
    position: absolute;
    left: 50%;
    top: 10vh;
    transform: translate(-50%, 0);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 100%;
    width: calc(100% - 70px);
    height: 85vh;
    padding: 30px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;

    .popup-inner {
        width: 100%;
        text-align: center;

        p {
            margin-bottom: 14px;

            &.gray {
                color: #414141;
            }
        }

        .btn {
            max-width: 255px;
            margin: 0 auto;
        }

        .buttons-payment {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        &.printer {
            .center {
                .btn {
                    margin-bottom: 15px;
                }
            }

            .disabled {
                filter: grayscale(80%) brightness(2);
            }

            .badge {
                &.loading {
                    .circle {
                        animation: spin .7s $CubicEaseInOut infinite;

                        &:after {
                            animation: spin2 .7s $CubicEaseInOut infinite;
                        }
                    }

                    .status {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        &:before {
                            content: ". . .";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100;
                            color: #fff;
                            font-weight: 700;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .close-modal {
        position: absolute;
        top: -19px;
        right: 19px;
        width: 38px;
        height: 38px;
    }
}

.bottom-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &_after-open {
        opacity: 1;

        .bottom-modal {
            transform: translateY(0);
        }
    }
    &_before-close {
        opacity: 0;

        .bottom-modal {
            transform: translateY(100%);
        }
    }

    .swipe-close {
        width: 100%;
        height: 100%;
    }
}

.bottom-modal {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: calc( 100vh - 50px );
    height: calc((var(--vh, 1vh) * 100) - 50px );
    border-top-right-radius: 33px;
    border-top-left-radius: 33px;
    box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
    transform: translateY(100%);
    transition: transform .4s $QuadEaseInOut;
    padding: 20px 10px;
    text-align: center;
    outline: none;

    .btn-close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        background: none;
        border: none;
        display: block;
        text-align: center;
        outline: none;

        .icon {
            position: relative;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-image: linear-gradient(to bottom, #0047af, #002277);
            margin: 0 auto;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        p {
            text-transform: uppercase;
            margin-top: 10px;
            font-size: 10px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 5px;
        background-color: #d8d8d8;
        border-radius: 2.5px;
    }

    .popup-inner {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 5%;

        h2 {
            margin-top: 0;
        }

        &.error {
            h2, p {
                color: #d80000;
            }
        }

        &.cancel-transaction {
            .buttons-payment {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 20px;

                .btn {
                    min-width: 45%;
                    max-width: 45%;
                    width: 45%;
                }
            }
        }
    }
}

.popup-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.popup-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 280px;
    width: 100%;
    padding: 30px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.17);

    .popup-inner {
        width: 100%;
        text-align: center;

        h3 {
            margin-top: 0;
            margin-bottom: .5em;
            font-size: 1.2em;
        }

        .buttons {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        .hours-inputs {
            display: flex;
            align-items: center;

            input {
                margin: 10px 10px 20px;
            }
        }
    }
}

.action-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: rgba(0,0,0,.15);

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.action-modal {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 180px;
    transform: translate(-50%, -50%);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    width: calc(100% - 70px);
    padding: 10px 0;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 70px 15px rgba(0, 0, 0, 0.27);

    .popup-inner {
        width: 100%;
    }

    .add-edit-holder {
        position: relative;

        .input-box {
            position: fixed;
            top: 80px;
            left: 0;
            width: 350px;
            padding: 15px 35px 35px;
            background-color: #fff;
            border-radius: 40px;

            @include XGA {
                position: absolute;
                top: 100px;
            }

            h3 {
                margin-top: 1em;
                text-align: left;
            }

            @include XGA {
                &.input-box-territory {
                    left: 320px;
                }
    
                &.input-box-event {
                    left: calc(320px + 10%);
                }
    
                &.input-box-location {
                    left: calc(320px + 20%);
                }
            }

            &.input-box-date {
                text-align: center;

                @include XGA {
                    left: calc(320px + 35%);
                }

                .react-datepicker {
                    display: inline-block;
                    left: -238px;
                    top: 50px;
                    color: $blue;
                    border: $blue solid 1px;

                    .react-datepicker__header {
                        background-color: transparent;
                        border: none;
                    }

                    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
                        color: $blue;
                    }

                    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
                        background-color: $blue;
                    }

                    .react-datepicker__day-names {
                        display: none;
                    }

                    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
                        border-bottom-color: $blue;
                    }

                    .react-datepicker__navigation--next {
                        border-left-color: $blue;
                    }

                    .react-datepicker__navigation--previous {
                        border-right-color: $blue;
                    }

                    .react-datepicker__triangle {
                        display: none;
                    }

                    .react-datepicker__month-container {
                        background-color: #fff;
                    }
                }
            }

            @include XGA {
                &.input-box-time {
                    left: auto;
                    right: 60px;
                }
    
                &.input-box-personnel {
                    left: auto;
                    right: 0;
                }
            }
        }

        input {
            position: relative;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 240px;
            background-image: url(../img/search.svg);
            margin-top: -10px;
        }

        ul {
            margin: 1em 0;
            height: 260px;
            max-height: calc( 100vh - 470px );
            overflow-y: scroll;

            li {
                display: block;
                padding: 5px 0 3px 25px;
                font-size: 12px;
                font-weight: 700;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: left center;
                background-image: url(../img/dodaj.svg);
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                user-select: none;

                .availability {
                    font-weight: 300;
                }

                &.active {
                    background-color: #d9deeb;
                }

                &.hostess-name {
                    background-color: #ffe0e0;
                    &.available {
                        background-color: transparent;
                    }
    
                    &.active {
                        background-color: #d9deeb;
                    }

                    &.taken {
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 7px;
                            right: 5px;
                            width: 10px;
                            height: 10px;
                            background-color: #bd2020;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .date-inputs {
            display: flex;

            input {
                margin-right: 8px;
            }

            .icon-calendar {
                width: 100%;
                width: 23px;
                height: 25px;
                margin-top: 0;
            }
        }

        .time-inputs {
            display: flex;

            span {
                line-height: 45px;
                margin: 0 5px;
            }
        }
    }

    .buttons {
        p {
            min-width: auto;
            font-size: 10px;
            margin: 0;
            margin-bottom: 3px;
            margin-top: -10px;
            height: 23px;
        }

        input {
            width: 60px;
            height: 34px;
            margin-right: 5px;
            margin-bottom: 0;
        }

        .btn.small-btn {
            height: 34px;
            margin-top: 6px;
        }
    }

    .copy-select {
        position: absolute;
        bottom: -20px;
        right: 10px;
    }
}

.filter-modal {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    width: 700px;
    padding: 10px 0;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 70px 15px rgba(0, 0, 0, 0.27);

    .popup-inner {
        width: 100%;
        padding: 0 30px;

        .filters {
            display: flex;
            height: 400px;

            h3 {
                margin-top: .5em;
                margin-bottom: .5em;
            }

            .location-filters {
                margin-top: 2em;
                width: 50%;
                padding-right: 3%;
            }

            .utility-filters {
                margin-top: 2em;
                width: 50%;
                padding-left: 3%;
            }
            
            .input-holder {
                position: relative;

                .search-input {
                    padding-bottom: 2px;
                }
            }

            input {
                position: relative;
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: 95%;
                background-image: url(../img/search.svg);
            }

            .list-holder {
                display: none;

                &.active {
                    display: block;
                }
            }

            ul {
                z-index: 10;
                position: absolute;
                width: 100%;
                top: 45px;
                left: 0;
                padding: 1em 0;
                max-height: 260px;
                overflow-y: scroll;
                background-color: #fff;
    
                li {
                    display: block;
                    padding: 5px 0 3px 25px;
                    font-size: 12px;
                    font-weight: 700;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url(../img/dodaj.svg);
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    user-select: none;
    
                    .availability {
                        font-weight: 300;
                    }
    
                    &.active {
                        background-color: #d9deeb;
                    }
    
                    &.hostess-name {
                        background-color: #ffe0e0;
                        &.available {
                            background-color: transparent;
                        }
        
                        &.active {
                            background-color: #d9deeb;
                        }
    
                        &.taken {
                            position: relative;
    
                            &:after {
                                content: "";
                                position: absolute;
                                top: 7px;
                                right: 5px;
                                width: 10px;
                                height: 10px;
                                background-color: #bd2020;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            .btn {
                width: 140px;
                height: 38px;
                margin: 10px 5px;
                padding: 10px 10px;
            }
        }

        .export-wrap {
            h3 {
                margin: 1em 0 .5em;
            }
        }

        .date-row {
            display: flex;
            .since-date, .until-date {
                display: flex;
                align-items: center;
                margin: 5px;

                span {
                    color: #a8a8a8;
                }

                label {
                    margin: 0 8px;
                }
            }
        }

        .select-row {
            margin: 15px 0 15px 35px;

            label {
                font-weight: 700;
            }
        }

        .column-row {
            display: flex;
        }

        ul {
            max-height: 165px;
            overflow-y: scroll;
            margin-bottom: 20px;

            li {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-top: rgba(0, 34, 119, 0.15) solid 1px ;

                .btn {
                    padding: 10px 15px;
                    font-size: 10px;
                }

                .small {
                    font-size: 10px;
                    opacity: .7;
                }
            }
        }
    }
}

.modal-info-inner {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    padding: 30px 0;

    .btn {
        margin: 40px auto 0;
        max-width: 250px;
    }
}

.overlay.photo {
    background: rgba(0, 0, 0, 0.8);
}

.modal.photo {
    padding: 0;
    width: fit-content;
    height: fit-content;
    
    .content {
        display: grid;

        * {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        img {
            display: block;
            max-width: 90vw;
            max-height: 80vh;
        }

        button {
            background-color: white;
            z-index: 2;
            width: 24px;
            height: 24px;
            padding: 0;
            display: grid;
            place-items: center;
            border-radius: 50%;
            border: none;
            margin-top: 10px;
            justify-self: flex-end;
            margin-right: 10px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}