// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

body {
  font-family: $main;
  font-size: 14px;
  color: $blue;
  line-height: 1.3;
}

section {
  padding: 16px 35px 40px;
}

.app {
  position: relative;
}

.page {}

.title {}

.page-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 92px 10px 140px;

  h1 {
    font-size: 36px;
    margin: 0;
    //width: 100%;
    flex-grow: 1;
    text-align: left;
  }
}

.bat-logo {
  width: 60px;
  position: absolute;
  top: 38px;
  left: 35px;
}

.color-line {
  width: 100%;
  height: 16px;
  border: 0;
  display: block;
}

.offline-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: #f44;
  text-align: center;
  z-index: 999999;

  p {
    color: #fff;
    font-size: 10px;
    padding: 3px;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

h1,
h2,
input {
  color: $blue;
  margin: 0;
}

h2,
h1 {
  font-size: 22px;
  text-align: center;
}

h3 {
  font-size: 18px;
  margin: 1.5em 0 1.2em;
}

h1,
h2 {
  margin-bottom: .8em;
}

p,
a,
input {
  font-size: 14px;
  text-decoration: none;
}

input {
  border-radius: 4px;
  border: solid 1.5px $blue;
  padding: 13px 15px;
  outline: none;
  background-color: #fff;
  margin-bottom: 16px;
  display: block;
  width: 100%;

  &:focus {
    background-color: #eceff7;
  }

  &::placeholder {
    color: #afafaf;
    font-style: italic;
  }
}

.btn {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0047af, $blue);
  font-weight: 700;
  border: none;
  color: #fff;
  padding: 14.5px 15px;
  text-align: center;
  display: block;
  width: 100%;
  outline: none;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:active {
    transform: scale(0.96);
  }

  &.white {
    border: $blue solid 1.5px;
    background: transparent;
    color: $blue;
  }

  &.orange-btn {
    background-image: linear-gradient(to bottom, #ffb80d, #da9e0d);
  }

  &.empty-btn {
    background-color: transparent;
    background-image: none;
    border: 2px solid $blue;
    color: $blue;
  }

  &.redeem-btn {
    width: 70px;
    font-size: 12px;
    padding: 10px;
  }

  &.inactive-btn {
    opacity: .5;

    &:active {
      transform: scale(1);
    }
  }

  &.export-btn {
    width: 140px;
    height: 38px;
    padding: 10px 15px;
    margin-right: 30px;
  }

  &.add-action-btn {
    position: relative;
    width: 205px;
    min-width: 205px;
    height: 38px;
    padding: 10px 15px;

    span {
      position: absolute;
      right: 20px;
      top: 8px;
      font-weight: 300;
      font-size: 20px;
      border: #fff solid 1px;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      line-height: 20px;
      margin-left: 20px;
      text-align: center;
      padding-right: 1px;
    }
  }
}

div.center {
  text-align: center;
}

.badge {
  position: relative;
  width: 140px;
  height: 140px;
  margin: 30px auto;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-image: url(../img/badge.jpg);

  .circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(176deg, #001a5b 3%, #00b6e5 38%, #0cb9e6 84%, #3bbb69 112%, #4f71c8 112%);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

    &:before {
      content: "";
      position: absolute;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-color: #eceff7;
      border-radius: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      left: 12px;
      top: 12px;
      width: calc(100% - 24px);
      height: calc(100% - 24px);
      background-size: cover;
      background-position: center;
      background-image: url(../img/badge.jpg);
      border-radius: 50%;
    }
  }

  &.ok {
    .status {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
      height: 26px;

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 34px;
        height: 3px;
        border-radius: 2px;
        background-color: #fff;
        transform: rotate(-45deg);
      }

      &:after {
        content: "";
        position: absolute;
        left: 1px;
        bottom: 5px;
        width: 17px;
        height: 3px;
        border-radius: 2px;
        background-color: #fff;
        transform: rotate(45deg);
      }
    }
  }

  &.failed {
    .circle {
      background: #fff;
      background-image: none;
    }

    .status {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
      height: 42px;

      &:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 20px;
        width: 34px;
        height: 3px;
        border-radius: 2px;
        background-color: #fff;
        transform: rotate(-45deg);
      }

      &:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 20px;
        width: 34px;
        height: 3px;
        border-radius: 2px;
        background-color: #fff;
        transform: rotate(45deg);
      }
    }
  }

  &.error {
    .circle {
      background: #d80000;
      background-image: none;
    }

    .status {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 700;
      font-size: 23px;
    }
  }

  &.question {
    .status {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 700;
      font-size: 23px;
    }
  }
}

.bottom-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 66px;
  z-index: 100;
  background-color: #fff;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    li {
      min-width: 33.33%;
      max-width: 33.33%;
      width: 33.33%;
      text-align: center;

      a {
        display: block;
        height: 100%;
        width: 100%;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
        background-color: $blue;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        opacity: 0.85;
        padding-top: 10px;

        &.active {
          opacity: 1;
          background-image: linear-gradient(to bottom, #0047af, $blue);
        }

        .icon {
          height: 36px;
        }
      }
    }
  }
}

.table-holder {
  width: 100%;
  overflow-x: auto;
}

table {
  border-spacing: 0;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 50px;
  border-collapse: collapse;

  thead {
    //height: 48px;
    //background-image: linear-gradient(to bottom, #0047af, $blue);
    //color: #fff;

    td {
      text-align: left;
      //height: 48px;
      padding: 12px 8px;
      white-space: nowrap;
      font-size: 12px;
      border: rgba(0, 34, 119, 0.15) solid 1px;
      border-top: 0;
      color: #a8a8a8;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  tbody {
    //height: 70vh;
    overflow-y: scroll;

    td {
      position: relative;
      color: $blue;
      text-align: left;
      padding: 12px 8px;
      border: rgba(0, 34, 119, 0.15) solid 1px;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      .buttons {
        display: flex;
      }

      .btn {
        font-weight: 400;
        padding: 10px 10px;
        font-size: 10px;
        margin-right: 10px;
        width: 66px;

        &:last-child {
          margin: 0;
        }
      }

      p {
        margin: 0;
      }

      &.action-name {
        padding: 4px 2px;

        span {
          display: block;
          min-width: 240px;
          width: 100%;
          overflow: hidden;
          height: 32px;
          padding: 7px 12px;
          margin: 0;
          color: #fff;
          font-weight: 700;
          background-color: $light-gray;
          border-radius: 16px;
        }
      }

      &.status-cell {
        position: relative;
        padding: 4px 12px;

        &:focus {
          outline: none;
        }

        .status-icon {
          height: 31px;
          width: 31px;
          background-color: $light-gray;
          border-radius: 50%;
          cursor: pointer;
        }

        .status-select {
          position: absolute;
          top: calc(50% - 30px);
          left: 100%;
          background-color: #fff;
          border-radius: 24.5px;
          box-shadow: 15px 8px 54px 4px rgba(0, 0, 0, 0.36);
          padding: 10px 10px;
          z-index: 99;
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

          li {
            margin-bottom: 5px;
            padding: 0;

            svg {
              display: block;
              cursor: pointer;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          &.open {
            opacity: 1;
            visibility: visible;
          }

          &:before {
            content: "";
            position: absolute;
            top: 25px;
            left: -4px;
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
            background-color: #fff;
          }
        }
      }
    }

    tr {
      &.cancelled {
        td {
          &.action-name {

            span {
              background-color: $gray;
            }
          }

          &.status-cell {
            .status-icon {
              background-color: $gray;
            }
          }
        }
      }

      &.hst_cancelled {
        td {
          &.action-name {

            span {
              background-color: #FF8D03;
            }
          }

          &.status-cell {
            .status-icon {
              background-color: #FF8D03;
            }
          }
        }
      }

      &.ended,
      &.unavailable,
      &.finished {
        td {
          &.action-name {

            span {
              background-color: $red;
            }
          }

          &.status-cell {
            .status-icon {
              background-color: $red;
            }
          }
        }
      }

      &.started {
        td {

          &.action-name {

            span {
              background-color: $green;
            }
          }

          &.status-cell {
            .status-icon {
              background-color: $green;
            }
          }
        }
      }

      &.new {}

      &.waiting,
      &.available,
      &.accepted {
        td {
          &.action-name {

            span {
              background-color: $light-blue;
            }
          }

          &.status-cell {
            .status-icon {
              background-color: $light-blue;
            }
          }
        }
      }
    }
  }

  &.codes-table {
    tbody {

      .codes-row {}

      .group-row {
        display: none;

        &.active {
          display: table-row;
        }
      }

      td {
        &:first-child {
          width: 54px;
        }

        &.codes-name {
          cursor: pointer;

          &:after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 13px;
            right: 15px;
            border-top: 2px solid $blue;
            border-right: 2px solid $blue;
            transform: rotate(135deg);
          }

          &:hover {
            background-color: #eceff7;
          }
        }
      }
    }
  }

  &.actions-table {

    tbody {
      .no-border-bottom {
        td {
          border-bottom: none;
        }
      }

      td {
        &:first-child {
          width: 54px;
        }

        &:last-child {
          width: 160px;
        }

        &.action-name {
          width: 260px;
        }

        &.action-location-with-distance {
          // background-color: lime;
          padding-right: 3px;

          .flex-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;

            .location {
              position: relative;
              display: block;
              width: 21px;
              height: 21px;
              margin: 0;
              flex-shrink: 0;
              background-color: #FF8D03;
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              border-radius: 50%;
              color: #FFFFFF;
              cursor: pointer;

              &:hover {
                .tooltip {
                  visibility: visible;
                  opacity: 1;
                }
              }

              .tooltip {
                position: absolute;
                visibility: hidden;
                opacity: 0;
                padding: 9px 20px 9px;
                background-color: #FFFFFF;
                box-shadow: 2px 1px 32px 2px rgba(0, 0, 0, 0.12);
                border-radius: 40px;
                top: -44px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 300ms ease-in-out;

                p {
                  color: #002277;
                  margin: 0;
                  white-space: nowrap
                }
              }
            }
          }
        }
      }

      .report-photos {
        td {
          padding: 20px 0;
          border: 0;
        }

        .photos-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;

          .photo {
            background-color: #FFFFFF;
            border: 1px solid #EAEAEF;
            box-shadow: 0px 1px 4px rgba(33, 33, 52, 0.1);
            border-radius: 4px;

            img {
              max-width: 128px;
              aspect-ratio: 1/1;
              object-fit: cover;
              border-radius: 4px;
            }

            p {
              margin: 0;
              text-align: center;
              padding: 3px 0px 5px;
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
              color: #8E8EA9;
            }
          }
        }
      }
    }
  }

  &.personnel-table {
    thead {
      td {
        &:nth-child(2) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            width: 350px;
          }

          &:nth-child(2) {
            text-align: center;
          }

          &:last-child {
            width: 130px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }

    .btn {
      width: 100px;
    }
  }

  &.add-edit-table {
    margin: 0;

    tbody {
      td {
        border: 0;
        border-right: 1px solid #fff;

        .buttons {
          justify-content: flex-end;
        }

        &:first-child {
          width: 54px;
        }

        &:last-child {
          width: 160px;
          border: 0;
          padding-right: 20px;
        }

        &.action-name {
          width: 260px;
        }
      }
    }
  }
}

.page-preloader {
  position: fixed;
  left: 0;
  top: 8px;
  width: 100%;
  height: calc(100% - 8px);
  background-color: #fff;
  z-index: 9999;
  transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  span {
    position: absolute;
    left: calc(50% - 17px);
    top: calc(50% - 17px);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border-left: $blue solid 4px;
    border-top: $blue solid 4px;
    border-right: $yellow solid 4px;
    border-bottom: $green solid 4px;
    animation: spin .8s $CubicEaseInOut infinite;
  }
}

.disclaimer-dropdown-wrapper {
  position: relative;

  .dropdown-container {
    background: white;
    height: auto;
    width: 100%;
    box-shadow: 0px 11px 31px 5px rgba(0, 0, 0, 0.3);
    z-index: 99;
    position: absolute;
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all $QuadEaseInOut;
    color: #131313;
    padding: 1rem;
    font-size: 0.75rem;

    .cancel {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 16px;
      cursor: pointer;
    }

    strong {
      font-weight: 600;
    }

    a {
      color: #131313;
      font-weight: 600;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.checkbox-wrapper {
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem 0;

  &.required {
    input {
      border: solid 1px #c21807;
      transition: 0.3s all;
    }

    label {
      color: #c21807;
      transition: 0.3s all;

      * {
        color: #c21807 !important;
      }
    }
  }

  input {
    appearance: none;
    width: 26px !important;
    height: 26px !important;
    border: solid 1px #d0d0d0;
    border-radius: 0;
    margin-right: 1rem;
    transition: 0.3s all;
    cursor: pointer;
    position: relative;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:after {
      z-index: 0;
      content: '';
      position: absolute;
      left: 55%;
      top: 40%;
      width: 6px;
      height: 12px;
      border-bottom: #000 solid 2px;
      border-right: #000 solid 2px;
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: 0.3s $QuadEaseInOut opacity;
    }

    &:checked {
      &:after {
        opacity: 1;
      }
    }
  }

  label {
    font-size: 0.75rem;
    line-height: 1.5;
    cursor: pointer;
    transition: 0.3s all;

    a {
      color: #000;
    }
  }
}

form {
  .btn {
    margin-top: 15px;

    &:disabled {
      background: linear-gradient(180deg, #BCBCBC 0%, #7E7E7E 100%);
    }
  }
}

.btn-margin {
  margin-top: 15px;
}

.input-wrapper {
  margin-top: 16px;

  &.hidden {
    visibility: hidden;
    height: 0;
    width: 0;

    &>* {
      width: 0;
      height: 0;
    }
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;
    display: block;
  }

  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  input[type="date"],
  input[name="billingPostalCode"] {
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    height: 44px;

    &::placeholder {
      color: rgba(102, 112, 133, 0.7);
      font-style: normal;
    }

    &:focus,
    &:focus-visible {
      border-color: #002277;
    }
  }

  .react-select {
    &__control {
      height: 44px;
      border-radius: 8px;
      box-shadow: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      .react-select__indicator {
        svg {
          transition: ease-in-out 200ms all;
        }
      }

      &--menu-is-open {
        .react-select__indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &--is-focused {
        border-color: #002277;
        border-width: 1px;

        &:hover {
          border-color: #002277;
        }
      }
    }

    &__value {
      &-container {
        padding: 0 14px;
        grid-template-columns: 1fr;
      }
    }

    &__indicator {
      padding: 0 12px 0 0;

      svg {
        path {
          fill: #667085;
        }
      }

      &-separator {
        display: none;
      }
    }

    &__menu {
      border-radius: 8px;
      overflow: hidden;

      &-list {
        padding: 0;
      }
    }

    &__option {
      border-radius: 0;
      padding-inline: 14px;
      // padding: 10px 14px;

      &--is-selected {
        position: relative;

        &::before {
          content: "";
          top: 50%;
          transform: translateY(-50%);
          right: 17px;
          width: 14px;
          height: 10px;
          display: block;
          position: absolute;
          background-image: url("../img/check.svg");
          background-size: contain;
          background-position: center;
        }

        // background-color: #F9FAFB;
        // color: #101828;
      }
    }
  }

  &.disabled {
    label {
      color: #A5ADB5;
    }

    input[type="number"]:disabled,
    input[type="email"]:disabled,
    input[type="tel"]:disabled,
    input[type="text"]:disabled,
    input[type="date"]:disabled,
    input[type="billingPostalCode"]:disabled {
      background-color: #F2F2F2;
      border: 1px solid #C4C4C4;
      color: #34405499;
    }

    .react-select {
      &__control {
        background-color: #F2F2F2;
        border: 1px solid #C4C4C4;
        color: #34405499;
      }

      &__indicator {
        display: none;
      }
    }
  }

  &.error {
    input {
      border: 1px solid #FDA29B;

      &:focus,
      &:focus-visible {
        border-color: #FDA29B;
      }
    }

    .react-select {
      &__control {
        border: 1px solid #FDA29B;
        border-color: #FDA29B;
      }
    }

    .input:has(:not(input[type="date"])) {
      position: relative;

      &::before {
        content: url("../img/input-error.svg");
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 14px;
        width: 16px;
        height: 16px;
        display: block;
      }
    }


    div.error {
      margin-top: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #F04438;
    }
  }
}

.checkbox-wrapper {
  padding: 0;
  margin-top: 19px;
  display: block;

  &:first-of-type {
    margin-top: 0;
  }

  label {
    display: grid;
    gap: 12px;
    grid-template-columns: 18px 1fr;
    align-items: flex-start;

    input[type="checkbox"] {
      width: 18px !important;
      height: 18px !important;
      min-width: initial !important;
      max-width: initial !important;
      min-height: initial !important;
      max-height: initial !important;
      margin: 0;
      position: relative;
      border: 1px solid #A9B1B9;
      border-radius: 4px;

      &::after {
        display: none;
      }

      &:checked {
        border-color: #0D245D;

        &::after {
          display: block;
          width: 100%;
          height: 100%;
          transform: initial;
          left: 0;
          top: 0;
          border: none;
          background-color: #0D245D;
          background-image: url("../img/checkbox-check.svg");
          background-position: left 3px top 4px;
          background-repeat: no-repeat;
        }
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #32475C;

      a {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &.error {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F04438;
  }
}

p.consent-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #32475C;
  margin-top: 24px;
}

h4.consent-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 24px;
}

.phone-number-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 105px auto;

  .input-wrapper:first-child {
    .react-select__control {
      border-radius: 8px 0 0 8px;
    }
  }

  .input-wrapper:last-child {
    padding-top: 26px;

    input {
      border-radius: 0 8px 8px 0;
    }
  }
}

section.form-section {
  padding: 120px 5%;
  padding-bottom: 80px;

  h1,h2 {
    text-align: center;
  }
  
  h3 {
    color: #212121;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(50, 71, 92, 0.22);
    margin-bottom: 16px;
    display: flex;

    span {
      margin-left: auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      align-self: flex-end;
    }
  }

  h4 {
    color: #212121;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(50, 71, 92, 0.22);
    width: fit-content;
  }
}

h2.webform-title {
  color: #000;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}

.webform-step {
  p.current-step-title {
    margin: 8px 0 0;
    color: #027;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  p.current-step-desc {
    margin: 16px 0 24px;
    color: #027;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

    strong {
      font-weight: 700;
    }
  }

  .step-progress {
    display: flex;
    gap: 4px;
    margin: 4px 0 0;

    .step {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #d0d5df;
      display: grid;
      place-items: center;

      &.active {
        background-color: #0E2B63;
      }

      span {
        color: #FFF;
        font-family: Arial;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}

.fixed-button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 68px;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  padding: 11px 24px;

  .flex-wrapper {
    display: flex;
    gap: 14px;

    &>* {
      flex: 1 1 50%;
      width: 0;
      margin: 0;
    }

    .btn {
      margin-top: 0;
      height: 100%;
    }
  }
}

.verify-user {
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #002277;
    margin-bottom: 40px;
  }

  p.resend-otp {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 0;

    a {
      font-weight: 900;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #01267C;

      &:visited {
        color: #01267C;
      }
    }
  }
}

section.select-offers-section {
  padding: 120px 5%;
  padding-bottom: 80px;

  h1,h2 {
    text-align: center;
  }
  
  .webform-offer {
    // overflow: clip;
    border-radius: 12px;
    border: 1px solid #E4E7EC;
    background: #FFF;
    margin-top: 16px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }


    &.highlighted {
      border: 2px solid #f44;

      .checkbox.checkbox {
        border: 1px solid #f44;
      }
    }

    &.selected {
      border: 2px solid #38549C;
      background: rgba(56, 84, 156, 0.05);

      .checkbox.checkbox {
        position: relative;
        border: 1px solid #0D245D;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #0D245D;
          background-image: url("../img/checkbox-check.svg");
          background-position: left 3px top 4px;
          background-repeat: no-repeat;
        }
      }
    }

    .summary {
      padding: 16px;
      background-color: transparent;
      appearance: none;
      display: block;
      border: none;
      display: grid;
      grid-template-columns: 80px 1fr 24px;

      &>img {
        width: 80px;
      }

      .text {
        margin: 0 4px 0 12px;
        display: flex;
        flex-direction: column;


        &>* {
          margin: 0;
          text-align: left;
          color: #000;
          font-family: Arial;
        }

        h3 {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;


          &.error {
            margin-top: 8px;
            color: #f44;
          }
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.12px;
          margin-bottom: auto;
        }
      }

      button {
        border: 0;
        padding: 0;
        background-color: transparent;
        height: 24px;

        .checkbox {
          width: 18px;
          height: 18px;
          margin: 3px;
          border: 1px solid #A9B1B9;
          border-radius: 4px;
        }
      }

      .checkbox-wrapper {
        margin: 0;
        height: 18px;

        label {
          place-items: center;
          gap: 0;
          grid-template-columns: 1fr;
        }
      }
    }

    .inputs-wrapper {
      padding: 0 16px 16px;

      &>*:first-child {
        margin-top: 0;
      }
    }
  }
}

.birthday-postal-code {
  display: flex;
  width: 100%;
  gap: 16px;

  &>* {
    flex-grow: 1;
    flex-basis: 100%;
  }
}

.form-section .success-page {
  .success-box {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #D2D7DB;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      gap: 8px;

      &.error {
        .icon {
          border: 1px solid #d80000;
          border-radius: 50%;
          display: grid;
          place-items: center;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: #d80000;
            }
          }
        }

        p {
          color: #d80000;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        flex-grow: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        margin: 0;
        color: #000;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        strong {
          font-weight: 700;
        }
      }
    }
  }
}

.img-container {
  margin-top: 16px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 51% auto;
  height: 190px;
  gap: 16px;

  canvas {
    display: none;
  }

  .left {
    width: 100%;
    height: 187px;
    display: grid;
    place-items: center;
    border: 1px solid #001854;
    height: 100%;
    border-radius: 4px;

    div {
      width: 100%;
    }

    .final-img {
      max-width: 100%;
      max-height: 188px;
      // object-fit: contain;
    }
  }

  .hidden {
    display: none;
  }

  .right {
    width: 100%;
    height: 100%;
    border: 0;
    background: linear-gradient(180deg, #0047AF 0%, #002277 100%);
    border-radius: 4px;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

button:disabled {
  background: linear-gradient(180deg, #BCBCBC 0%, #7E7E7E 100%);
}

.disable-pointer-events {
  pointer-events: none;

  .input {
    background: #AFAFAF;
    color: white !important;
    border: none;
  }
}

.neo-offer {
  margin-top: 14px;
  margin-bottom: 14px;

  label {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 12px;
    position: relative;
    height: 24px;

    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: white;
      border: 1px solid #001854;
    }

    p {
      margin: 0;
      align-self: center;
      font-weight: 400;
      font-size: 12px;
    }

    input[type="checkbox"] {
      height: 0;
      width: 0;
      border: none;

      &:checked {
        &::before {
          content: url("../img/check.svg");
          background: linear-gradient(180deg, #0047AF 0%, #002277 100%);
          width: 24px;
          height: 24px;
          position: absolute;
          display: grid;
          place-items: center;
          border-radius: 50%;
        }
      }
    }
  }
}

.disable-scroll {
  overflow: hidden;
}

.camera-output {
  z-index: 1000;
  background-color: white;
  padding: 4% 10%;
  border-radius: 24px;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 25px 1fr 84px;
  gap: 24px;

  canvas {
    visibility: hidden;
    position: absolute;
    z-index: -10;
  }

  button {
    background: none;
    outline: none;
    border: none;
    padding: 0;
  }

  .top-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .video-container {
    background-color: #C4C4C4;
    // margin-top: 35px;
    // margin-bottom: 24px;
    position: relative;
    height: 100%;

    .hidden {
      display: none;
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      // aspect-ratio: 11/16;
      margin-inline: auto;
      max-width: 100%;
      max-height: 700px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      border: 3px solid white;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    div,
    a {
      display: contents;
      pointer-events: none;
    }
  }

  .bottom-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .camera-button {
      width: 84px;
      height: 84px;
      background: #E4E4E4;
      border-radius: 50%;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 70px;
        height: 70px;
        background: linear-gradient(180deg, #0047AF 0%, #002277 100%);
        border-radius: 50%;
        left: 7px;
        top: 7px;
      }
    }
  }
}