header {
	position: fixed;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	z-index: 999;
	width: 100%;

	.burger {
		position: relative;
		width: 27px;
		height: 16px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}

		span {
			position: absolute;
			left: 0;
			top: 8px;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}
	}

	nav {
		opacity: 0;
		visibility: hidden; 
		background-color: $blue;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&.open {
			opacity: 1;
			visibility: visible;
		}

		.close {
			position: absolute;
			left: 0;
			top: 2.1vw;
			width: 46px;
			height: 46px;

			&:before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(-45deg);
				width: 20px;
				height: 2px;
				background-color: #fff;
			}

			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
				width: 20px;
				height: 2px;
				background-color: #fff;
			}
		}

		ul {
			padding-top: 100px;

			li {
				text-align: center;

				a, span {
					display: block;
					color: #fff;
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 700;
					text-align: center;
					text-decoration: none;
					padding: 20px;
				}
			}
		}
	}
}
