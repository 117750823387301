.pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 15px;
  font-weight: 700;
  box-shadow: 0 2px 18px 0px rgba(0, 0, 0, 0.2);

  li {
    margin: 0 3px;
    a {
      cursor: pointer;
      display: block;
      padding: 10px 0;
      min-width: 35px;
      text-align: center;
      border: 1px solid rgb(226, 226, 226);
      border-radius: 5px;
    }
  }
  .active {
    a {
      border: 1px solid $blue;
    }
  }
  .previous {
    margin: 0 10px;
    cursor: pointer;

    a {
      padding: 10px 10px;
    }
  }

  .next {
    margin: 0 10px;
    cursor: pointer;

    a {
      padding: 10px 10px;
    }
  }

  .disabled {
    color: #ccc;
  }
}