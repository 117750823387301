.toasts {
    z-index: 99999;
    position: fixed;
    top: 0;
    right: 15px;
    padding-top: 20px;
    width: 200px;

    .single-toast {
      position: relative;
      width: 200px;
      margin-top: 15px;
      padding: 12px 15px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.15);
      font-size: 10px;
      font-weight: 700;
      color: #002277;
      text-transform: uppercase;

      &.error-toast {
          color: #fff;
          background-color: #f44;
      }

      &.cart-add {
        &:after {
          content: "";
          position: absolute;
          top: -10px;
          right: 10px;
          border-top: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #f44;
        }
      }
    }
}

 .toast-enter {
   opacity: 0.01;
 }

 .toast-enter.toast-enter-active {
   opacity: 1;
   transition: opacity 400ms $QuadEaseOut;
 }

.toast-exit {
   opacity: 1;
 }

.toast-exit.toast-exit-active {
   opacity: 0.01;
   transition: opacity 200ms ease-in;
 }