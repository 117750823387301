.actions, .personnel {
	.calendar-dropdown {
		position: relative;
		margin-right: 40px;
		text-align: right;

		.selected {
			display: block;
			position: relative;
			font-size: 18px;
			font-weight: 700;
			text-transform: capitalize;
			margin: 10px 0;
			cursor: pointer;
			padding-left: 30px;
			white-space: nowrap;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 4px;
				width: 8px;
				height: 8px;
				border-left: $blue solid 1px;
				border-bottom: $blue solid 1px;
				transform: rotate(-45deg);
			}
		}

		.month-dropdown {
			display: flex;
			position: absolute;
			top: calc( 100% );
			right: -20px;
			padding: 20px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: rgba($color: #000000, $alpha: 0.3) 0px 0px 30px;
			z-index: 999;
			opacity: 0;
			visibility: hidden;
			transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

			&.open {
				opacity: 1;
				visibility: visible;
			}

			&:before {
				content: "";
				width: 8px;
				height: 8px;
				background-color: #fff;
				position: absolute;
				left: 63px;
				top: -5px;
				transform: rotate(45deg);
			}

			li {
				padding: 10px 0;
				cursor: pointer;
				text-transform: capitalize;
			}

			.since-date, .until-date {
				display: flex;
				align-items: center;
				margin: 5px;

				span {
					color: #a8a8a8;
				}

				label {
					margin: 0 8px;
				}
			}
		}
	}

	.action-item {
		display: flex;
		justify-content: space-between;
		height: 45px;
		margin: 15px 0;

		&.active {
			.start-stop {
				&:before {
					display: block;
				}
				&:after {
					display: none;
				}
			}

			.action-name {
				background-color: $green;
			}
		}

		.action-name {
			padding: 14px 0 0 30px;
			width: calc(100% - 55px);
			background-color: $light-blue;
			color: #fff;
			font-weight: 700;
			border-radius: 24px;
			transition: .1s background-color $QuadEaseOut;
		}

		.start-stop {
			position: relative;
			width: 45px;
			height: 45px;
			background-image: linear-gradient(to bottom, #0047af, #002277);
			border-radius: 50%;

			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 20px;
				border-left: 10px solid #fff;
				border-right: 10px solid transparent;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
			}

			&:before {
				display: none;
				content: "";
				position: absolute;
				top: 16px;
				left: 16px;
				width: 13px;
				height: 13px;
				background-color: #fff;
			}
		}
	}
}

.action {
	.info {
		margin: 1em;
		color: #ff9310;
	}

	.action-info {
		margin-bottom: 2em;
	}
}

.code-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 10px;
	border-bottom: solid 1px #d8ddea;

	&.active {
		.redeem-btn {
			background-image: linear-gradient(to bottom, #dadada, #bcbcbc);
		}

		.cupon-code {
			opacity: 0.3;
		}
	}

	.nr {
		font-size: 12px;
	}

	.cupon-code {
		font-size: 18px;
		color: #002277;
		font-weight: 700;
	}

	.redeem {
		width: 27px;
		height: 27px;
		border: 1px solid #003da0;
		border-radius: 50%;
	}
}

.reports {
	.reports-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 60px 0 30px;
		height: 45px;
		margin: 15px 0;
		background-color: #0dbae7;
		border-radius: 24px;

		.report-name {
			color: #fff;
			font-weight: 700;
		}

		.date {
			font-size: 14px;
			color: #fff;
		}
	}
}

.report {
	.subtitle {
		font-size: 12px;
		color: $blue;
		text-align: center;
	}

	.report-details {

		.report-detail {
			display: flex;
			justify-content: space-between;
			padding: 8px 15px;
			border-bottom: solid 1px #d8ddea;
			color: $blue;

			.detail-name {
				font-weight: 700;
			}
		}
	}
}

.location {
	display: flex;
	margin: 1em 0;

	.location-icon {
		width: 55px;
	}

	.location-data {
		text-align: left;
		color: $blue;

		.location-id {
			font-weight: 700;
		}

		.location-date {
			font-weight: 700;
		}
	}
}

.reports {
	.calendar-dropdown {
		margin-right: 0;
	}
}